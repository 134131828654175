







































import { Component, Mixins } from 'vue-property-decorator'
import axios from 'axios'
import camelCaseKeys from 'camelcase-keys'
import FlashMessageMixin from '@/mixins/flashMessageMixin'
import GenericModalForm from '@/partials/modals/generic/form.vue'
import PasswordConfirmField from '@/partials/forms/PasswordConfirmField.vue'
@Component({
  components: {
    PasswordConfirmField,
    GenericModalForm
  }
})
export default class MedicalFormTemplates extends Mixins(FlashMessageMixin) {
  templates: Array<any> = []
  modalIsVisible = false
  form: Record<string, any> = {
    templateId: '',
    confirm: '',
    errors: {
      confirm: ''
    }
  }

  created () {
    axios.get('/medical/form/template')
      .then(response => {
        this.templates = camelCaseKeys(response.data.data, { deep: true })
      })
  }

  postSubmit (response: string) {
    this.modalIsVisible = false

    this.$nextTick(() => {
      this.$router.push({ name: 'MedicalForms' }, () => {
        const flashMessage: flashMessage = {
          message: response,
          type: 'success'
        }
        this.setFlashMessage(flashMessage)
      })
    })
  }
}
