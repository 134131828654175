






import { Component, Vue } from 'vue-property-decorator'

import MedicalFormForm from '@/partials/forms/Medical/Form/form.vue'

@Component({
  components: {
    MedicalFormForm
  }
})
export default class MedicalFormsCreate extends Vue {

}
