








































import { Component, Mixins, Prop } from 'vue-property-decorator'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import axios from 'axios'
import camelCaseKeys from 'camelcase-keys'
import snakeCaseKeys from 'snakecase-keys'
import { namespace } from 'vuex-class'

import RichTextEditor from '@/partials/forms/components/RichTextEditor.vue'
import ValidatorConfigMixin from '@/mixins/validatorConfigMixin'
import PasswordConfirmField from '@/partials/forms/PasswordConfirmField.vue'
import FlashMessageMixin from '@/mixins/flashMessageMixin'
import NotifyOnFailed from '@/partials/forms/NotifyOnFailed.vue'

const sessionModule = namespace('session')

@Component({
  components: {
    RichTextEditor,
    PasswordConfirmField,
    NotifyOnFailed,
    ValidationProvider,
    ValidationObserver
  }
})
export default class MedicalFormForm extends Mixins(FlashMessageMixin, ValidatorConfigMixin) {
  @Prop({ required: true, type: String }) title!: string
  @Prop({ type: String }) formId!: string
  @Prop({ type: String }) type!: 'create' | 'edit'
  @sessionModule.Getter state!: Record<string, any>

  done = true

  form: Record<string, any> = {
    title: '',
    body: '',
    authorId: '',
    confirm: '',
    errors: {
      title: '',
      body: '',
      confirm: ''
    }
  }

  created () {
    this.form.authorId = this.state.user.id
    if (process.env.NODE_ENV === 'development' && this.type !== 'edit') {
      Object.assign(this.form, {
        title: 'Meu Questionario',
        body: `
          <h1>Título Teste</h1>
          <p>Esse é um paragrafo de teste</p>
          <ul>
            <li><strong>Lista 1</strong></li>
            <li><s>Lista 2</s></li>
            <li>Lista 3</li>
          </ul>
          <blockquote>Isso é uma citação</blockquote>
        `
      })
    } else if (this.type === 'edit') {
      axios.get(`/medical/form/${this.formId}`)
        .then(response => {
          Object.assign(this.form, camelCaseKeys(response.data.data, { deep: true }))
        })
    }
  }

  submit () {
    const verb = this.type === 'edit' ? 'put' : 'post'
    const route = this.type === 'edit' ? `/medical/form/${this.formId}` : '/medical/form'
    this.done = false
    axios[verb](route, snakeCaseKeys(this.form))
      .then(() => {
        this.redirectOnSuccess()
      })
      .catch(err => this.handleSubmitError(err))
      .finally(() => { this.done = true })
  }

  redirectOnSuccess () {
    const verb = this.type === 'create' ? 'cadastrado' : 'editado'

    const flashMessage = {
      message: `O Questionário foi ${verb} com sucesso.`,
      kind: 'success'
    }

    this.$router.push({ name: 'MedicalForms' }, () => this.setFlashMessage(flashMessage))
  }

  handleSubmitError (err: any) {
    const response = err.response

    const errors = response.data.errors
    if (errors) {
      Object.entries(errors).map(([key, value]: [string, any]) => {
        this.form.errors[key] = value[0]
      })
    }
  }

  resetErrors () {
    this.form.errors = {
      title: '',
      body: '',
      confirm: ''
    }
  }
}
